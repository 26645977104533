<template>
  <div :key="$route.path" class="container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>基本信息</span>
      </div>
      <div>
        <el-form :inline="true" label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="学生姓名 :">
                <span v-if="StudentInfo != null">{{ StudentInfo.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证号 :">
                <span v-if="StudentInfo != null">{{ StudentInfo.card }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别 :">
                <span v-if="StudentInfo != null">{{ StudentInfo.sex == 0 ? "男" : "女" }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="学生ID :">
                <span v-if="StudentInfo != null">{{ StudentInfo.id }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班主任 :" v-if="StudentInfo.classes.kxTeacher != null">
                <span v-if="StudentInfo != null">{{ StudentInfo.classes.kxTeacher.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班级 :">
                <span v-if="StudentInfo != null">{{ StudentInfo.className }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div slot="header" style="display: flex;">
        <div>
          <i class="el-icon-stopwatch" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">查询学生成绩学期选择:</span>
          <el-select v-model="leftSemester" placeholder="请选择">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <i class="el-icon-s-promotion" style="margin-right: 5px; margin-left: 5px; color: cornflowerblue;"></i>
          <el-select v-model="rightSemester" placeholder="请选择" style="margin-right: 15px;">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" round @click="clickTabButton">确定</el-button>
        </div>
      </div>
      <div v-loading="loading" element-loading-text="拼命加载中" ref="mains" style="width: auto;height: 300px"></div>
    </el-card>
  </div>
</template>

<script>
import { GetStudentinfo, GetStudentScore, AllSemester, GetSemester } from "../../api/thing"

export default {
  data() {
    return {
      StudentInfo: {
        classes: {
          kxTeacher: {
            name: "o"
          }
        }
      },
      leftSemester: 0,
      rightSemester: 0,
      source: [],
      allSemester: [],
      chartInstance: null,
      loading: true,
    };
  },
  created() {
    this.init()

  },
  methods: {
    async init() {
      this.loading = true
      await AllSemester().then(val => {
        this.allSemester = val.rows;
        GetSemester().then(value => {
          this.leftSemester = value.last.id;
          this.rightSemester = value.now.id;
          this.source[0] = ['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name]
        });
      });
      await GetStudentinfo(this.$route.params.id).then(val => {
        this.StudentInfo = val.data
      })
      this.queryStudentScore()



    },
    clickTabButton() {
      this.loading = true
      this.source = [];
      this.source[0] = ['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name];
      this.queryStudentScore();
    },
    defaultOptions() {
      const option = {
        legend: {},
        tooltip: {
        },
        dataset: {
          source: this.source
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[1];
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if ((val.data[1] > 4.0 && val.data[1] < 5.0) || (val.data[1] > 85.0 && val.data[1] < 100.0)) {
                  return "#61a0a8"
                } else if ((val.data[1] > 3.0 && val.data[1] < 4.0) || (val.data[1] > 75.0 && val.data[1] < 85.0)) {
                  return '#FFFF66'
                } else {
                  return '#CC0000'
                }
              },
            }
          }
        }, {
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[2];
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if ((val.data[2] >= 4.0 && val.data[2] <= 5.0) || (val.data[2] >= 85.0 && val.data[2] <= 100.0)) {
                  return "#61a0a8"
                } else if ((val.data[2] >= 3 && val.data[2] < 4) || (val.data[2] >= 75.0 && val.data[2] < 85.0)) {
                  return '#FFFF66'
                } else {
                  return '#CC0000'
                }
              },
            }
          }
        }],
        graphic: [
          {
            type: 'group',
            left: 'center',
            bottom: 10,
            children: [
              {
                type: 'text',
                z: 100,
                left: 'center',
                top: 'middle',
                style: {
                  fill: '#333',
                  text: "红色为成绩75或3分以下，黄色为成绩75或3分以上，蓝色为成绩85或4分以上",
                  font: '14px Microsoft YaHei'
                }
              }
            ]
          }
        ],
      };
      return option;
    },
    initEcharts() {
      this.chartInstance = this.$echarts.init(this.$refs.mains);
      this.chartInstance.showLoading();
      setTimeout(() => {
        this.chartInstance.setOption(this.defaultOptions(), true);
        this.chartInstance.hideLoading();
      }, 2000);
    },
    queryStudentScore() {
      GetStudentScore(this.$route.params.id, this.leftSemester).then(val1 => {
        GetStudentScore(this.$route.params.id, this.rightSemester).then(val2 => {
          this.extractAndMergeScores(val1.data, val2.data).forEach(item => {
            this.source.push(item)
          })
        })
      })
      setTimeout(() => {
        this.initEcharts();
        this.loading = false
      }, 5000);

    },
    extractAndMergeScores(data1, data2) {
      if (data1 == null) {
        const extractScores = (data) => {
          const keys = [
            "chineseScore",
            "mathScore",
            "englishScore",
            "scienceScore",
            "physicalScore",
            "artScore",
            "musicScore",
            "moralScore",
            "laoDongScore"
          ];

          const extractedData = keys.map(key => {
            let value1 = 0;
            let value2 = data2[key] || 0;
            return [this.editKey(key), value1, value2];
          });
          return extractedData;
        };
        // 合并数据
        const mergedData = extractScores(data2).concat(extractScores(data2));
        const halfIndex = Math.ceil(mergedData.length / 2);
        const halfMergedData = mergedData.slice(0, halfIndex);
        return halfMergedData;
      } else if (data2 == null) {
        const extractScores = (data) => {
          const keys = [
            "chineseScore",
            "mathScore",
            "englishScore",
            "scienceScore",
            "physicalScore",
            "artScore",
            "musicScore",
            "moralScore",
            "laoDongScore"
          ];

          const extractedData = keys.map(key => {
            let value1 = data1[key] || 0;
            let value2 = 0;
            return [this.editKey(key), value1, value2];
          });
          return extractedData;
        };
        // 合并数据
        const mergedData = extractScores(data1).concat(extractScores(data1));
        const halfIndex = Math.ceil(mergedData.length / 2);
        const halfMergedData = mergedData.slice(0, halfIndex);
        return halfMergedData;
      } else {
        const extractScores = (data) => {
          const keys = [
            "chineseScore",
            "mathScore",
            "englishScore",
            "scienceScore",
            "physicalScore",
            "artScore",
            "musicScore",
            "moralScore",
            "laoDongScore"
          ];

          const extractedData = keys.map(key => {
            let value1 = data1[key] || 0;
            let value2 = data2[key] || 0;
            return [this.editKey(key), value1, value2];
          });
          return extractedData;
        };
        // 合并数据
        const mergedData = extractScores(data1).concat(extractScores(data2));
        const halfIndex = Math.ceil(mergedData.length / 2);
        const halfMergedData = mergedData.slice(0, halfIndex);
        return halfMergedData;
      }
    },
    editKey(key) {
      switch (key) {
        case "chineseScore":
          return "语文";
        case "mathScore":
          return "数学";
        case "englishScore":
          return "英语";
        case "scienceScore":
          return "科学";
        case "physicalScore":
          return "物理";
        case "artScore":
          return "美术";
        case "musicScore":
          return "音乐";
        case "moralScore":
          return "道德";
        case "laoDongScore":
          return "劳动";
        default:
          return "未知";
      }
    },
    getStudentinfo() {
      this.loading = true
      this.StudentInfo = {};
      GetStudentinfo(this.$route.params.id).then(val => {
        this.StudentInfo = val.data
      })
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getStudentinfo();
        this.clickTabButton()
      },
      deep: true
    }
  },
};
</script>

<style scoped></style>